@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

body{
    overflow-x: hidden;
}
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
    content: '' !important;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
    content: '' !important;
}

.swiper-button-next svg,
.swiper-button-prev svg {
    width: 24px !important;
    height: 24px !important;
}

.swiper-button-next,
.swiper-button-prev {
    position: relative !important;
    margin-top: 32px;
}

.swiper-slide.swiper-slide-active {
    --tw-border-opacity: 1 !important;
    border-color: rgb(79 70 229 / var(--tw-border-opacity)) !important;
}

.swiper-slide.swiper-slide-active>.swiper-slide-active\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgb(79 70 229 / var(--tw-text-opacity));
}

.swiper-slide.swiper-slide-active>.flex .grid .swiper-slide-active\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgb(79 70 229 / var(--tw-text-opacity));
}

/* .swiper-wrapper {
    height: max-content !important;

    width: max-content;
}

.swiper-button-next,
.swiper-button-prev {
    top: 25%;
    z-index: 1000;
}

.swiper-button-next {
    right: -0px !important;
}

.swiper-button-prev {
    left: 0px !important;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
    content: "" !important;
}

.mySwiper {
    max-width: 320px !important;
    margin: 0 auto !important;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
    content: "" !important;
}

.mySwiper .swiper-slide.swiper-slide-thumb-active>.swiper-slide\:w-16 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.mySwiper .swiper-slide.swiper-slide-thumb-active>.swiper-slide\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgb(79 70 229 / var(--tw-border-opacity));
}

.teamswiper .swiper-wrapper {
    height: max-content !important;
    padding-bottom: 64px !important;
}

.teamswiper .swiper-horizontal>.swiper-scrollbar,
.teamswiper .swiper-scrollbar.swiper-scrollbar-horizontal {
    max-width: 140px !important;
    height: 3px !important;
    bottom: 25px !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
}

.teamswiper .swiper-pagination-fraction {
    bottom: 0 !important;
    padding-top: 16px !important;
}

.teamswiper .swiper-slide.swiper-slide-active>.slide\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgb(79 70 229 / var(--tw-border-opacity));
}

.teamswiper .swiper-pagination-total {
    color: rgb(156 163 175) !important;
}

.teamswiper .swiper-scrollbar-drag {
    background: rgb(79 70 229);
}

.teamswiper .swiper-pagination-fraction {
    bottom: 0 !important;
}

.teamswiper .swiper-button-prev:after,
.teamswiper .swiper-rtl .swiper-button-next:after {
    content: '' !important;
}

.teamswiper .swiper-button-prev {
    top: 93% !important;
    left: 35% !important;
    z-index: 100 !important;
}

.teamswiper .swiper-button-next {
    top: 93% !important;
    right: 35% !important;
    z-index: 100 !important;
}

.teamswiper .swiper-button-next:after,
.teamswiper .swiper-rtl .swiper-button-prev:after {
    content: '' !important;
}

.teamswiper .swiper-button-next svg,
.teamswiper .swiper-button-prev svg {
    width: 24px !important;
    height: 24px !important;
}




.swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
      content: '' !important;
  }

  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
      content: '' !important;
  }

  .swiper-button-next svg,
  .swiper-button-prev svg {
      width: 24px !important;
      height: 24px !important;
  }

  .swiper-button-next,
  .swiper-button-prev {
      position: relative !important;
      margin-top: 32px;
  }

  .swiper-slide.swiper-slide-active {
      --tw-border-opacity: 1 !important;
      border-color: rgb(79 70 229 / var(--tw-border-opacity)) !important;
  }

  .swiper-slide.swiper-slide-active>.swiper-slide-active\:text-indigo-600 {
      --tw-text-opacity: 1;
      color: rgb(79 70 229 / var(--tw-text-opacity));
  }

  .swiper-slide.swiper-slide-active>.flex .grid .swiper-slide-active\:text-indigo-600 {
      --tw-text-opacity: 1;
      color: rgb(79 70 229 / var(--tw-text-opacity));
  } */